// プライバシーポリシー
import React from 'react';
import { graphql, Link } from 'gatsby';
import Layout from '../components/layout';

const About = props => {
  const siteName = props.data.site.siteMetadata.title;
  const title = `このブログについて | ${siteName}`;
  const description = `当ブログ${siteName}はプログラムに関する情報を発信していきます。`;

  return (
    <Layout title={title} description={description} pageType="about">
      <div className="mb-12">
        <h2 className="text-2xl mb-4 md:text-4xl font-bold">
          「{siteName}」について
        </h2>
        <p>
          当ブログ「{siteName}
          」は、管理人のHeisen(ハイゼン)のプログラム学習記録やよく使うコード・設定などを備忘録としてまとめたものです。
        </p>
        <p>メインはWeb系です。</p>
        <p>
          言語・フレームワークとしてはReact・Python・Rustの記事が多くなると思います。
        </p>
      </div>
      <div>
        <h2 className="text-2xl mb-4 md:text-4xl font-bold">
          お問い合わせ・その他
        </h2>
        <p>
          お問い合わせは
          <Link className="text-blue-300" to="/contact">
            こちらから
          </Link>
        </p>
        <p>
          <a
            href="https://twitter.com/Heisen_Tech"
            target="_blank"
            rel="noreferrer"
            className="text-blue-300 hover:text-blue-700"
          >
            Twitterはこちら
          </a>
        </p>
      </div>
    </Layout>
  );
};

export default About;

export const aboutQuery = graphql`
  query aboutQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
